import './src/styles/_base.scss';

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
  const isAnchorLink = routerProps.location.hash.startsWith('#');

  if (isAnchorLink) return true;

  return routerProps.location.pathname !== prevRouterProps?.location?.pathname;
};

function initLegacyGTM() {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.async = true;
  script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.GATSBY_GA_TRACKING_ID}');`;

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', `${process.env.GATSBY_GA_TRACKING_ID}`);
  };

  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<iframe src=https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GA_TRACKING_ID} height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.head.insertBefore(script, document.head.childNodes[1]);
  document.body.insertBefore(noscript, document.body.childNodes[0]);
}

function initGTM() {
  if (window.isGTMLoaded) {
    return false;
  }
  window.isGTMLoaded = true;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `${process.env.GATSBY_GA_SERVER_CONTAINER_URL}/gtag/js?id=${process.env.GATSBY_GA_SERVER_TRACKING_ID}`;

  // script.onload = () => {
  //   window.dataLayer.push(['consent', 'update', {
  //     ad_storage: 'granted',
  //     analytics_storage: 'granted',
  //     ad_user_data: 'granted',
  //     google_analytics: 'granted',
  //   }]);
  // };

  document.head.appendChild(script);
}

function loadGTM(event) {
  initGTM();
  initLegacyGTM();
  event.currentTarget.removeEventListener(event.type, loadGTM);
}

export const onClientEntry = () => {
  document.addEventListener('statisticalCookies', loadGTM);
};

export const onRouteUpdate = ({ location }) => {
  const locale = location.pathname.split('/')[1];

  if (window.fetchLocale === locale) return;

  if (window.fetchLocale) {
    window.fetch = window.oldFetch;
  }

  const oldFetch = window.fetch;
  window.oldFetch = oldFetch;
  window.fetchLocale = locale;
  window.fetch = (path, config = null) => {
    if (config?.withoutLocale) {
      return oldFetch(path, config);
    }
    const pathWithLocale = `${path}${
      path.includes('?') ? '&' : '?'
    }language=${locale}`;
    return oldFetch(pathWithLocale, config);
  };
};
